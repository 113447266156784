<script>
  import api from "../../api";
  import { mapGetters } from "vuex";
  export default {
    data: () => {
      return {
        data: null,
      };
    },
    metaInfo() {
      const { blog = {} } = this.locale;
      const { keywords = "", description = "", title = "" } = blog;

      return {
        title,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description,
          },
          {
            vmid: "robots",
            name: "robots",
            content: "index, follow",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: keywords,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: description,
          },
        ],
      };
    },
    computed: {
      ...mapGetters(["newLocale"]),
      locale() {
        return this.newLocale;
      },
    },
    methods: {
      getData: async function () {
        let response = await api.get(`${process.env.VUE_APP_API_HOST}blog`);
        this.data = response.data;
      },
    },
    mounted: async function () {
      await this.getData();
      this.$root.$on("refreshlang", async (data) => {
        await this.getData();
      });
    },
  };
</script>

<template>
  <section>
    <div class="section-blog section" id="blog" v-if="data">
      <div class="section-blog-wrap">
        <div class="section-blog-header">
          <div class="section-blog-header-wrap main">
            <div class="section-blog-header-main" :style="`background-image: url(${this.$global.newUrl(data.bannerLink)})`"></div>
          </div>
        </div>
        <div class="section-blog-title">
          <div class="section-blog-title-top sec-font">{{ data.title }}</div>
          <div class="section-blog-title-sub">{{ data.subtitle }}</div>
        </div>
        <div class="section-blog-list">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .section-blog {
    text-align: center;
    margin-top: 70px;
    &-header {
      height: 200px;
      &-wrap {
        height: 100%;
        width: 100%;
        display: inline-block;
      }
      &-main {
        background-color: $white_smoke;
        height: calc(100% - #{$mpadding * 2});
        width: calc(100% - #{$mpadding * 2});
        margin: $mpadding 0 $mpadding $mpadding;
        border-radius: $mradius;
        background-size: cover;
        background-position: top 40% center;
        box-shadow: $dshadow;
      }
    }
    &-title {
      margin-top: -$mpadding * 2.2;
      &-top {
        font-size: 32px;
        color: $primary_color;
      }
      &-sub {
        font-size: 12px;
        line-height: 1.462;
        color: #5b5957;
      }
    }
    @media (min-width: $tablet_width) {
      &-header {
        height: 239.91px;
        margin-top: 70px;
        &-main {
          margin: $mpadding * 2 0 $mpadding $mpadding;
        }
      }
      &-title {
        margin-top: -$mpadding * 1.7;
        &-top {
          font-size: 42px;
        }
        &-sub {
          font-size: 16px;
          line-height: 1.625;
        }
      }
    }
  }
</style>
